import React from 'react';
import './SelectedDetails.css';

import {  IoArrowBack, IoSyncOutline  } from 'react-icons/io5'; // Import icon


  
const SelectedDetails = ({ selectedSportCenter, selectedCourt, selectedDate, selectedHour, OnhandleBack, OnhandleUpdate, stage, isNative , isMobile }) => {

 
  // Helper functions for formatting date and time
  const formatDate = (dt) => {
    if (dt instanceof Date) {
      const year = dt.getFullYear();
      const month = String(dt.getMonth() + 1).padStart(2, '0');
      const day = String(dt.getDate()).padStart(2, '0');
      return `${day}/${month}/${year}`;
    }
    return dt;
  };
  
    // Helper functions for formatting date and time
  const formatDate2 = (dt) => {
    if (dt instanceof Date) {      
      const month = String(dt.getMonth() + 1).padStart(2, '0');
      const day = String(dt.getDate()).padStart(2, '0');
      return `${day}/${month}`;
    }
    return dt;
  };
  
  


  const formatHour = (hour) => {
    return hour?.hour ? `${hour.hour.padStart(2, '0')}:00` : 'Invalid Hour';
  };

  // Conditionally render selected item based on the stage
  const renderSelectedItem = () => {
    switch (stage) {
      case 1:
        return <React.Fragment><h4>{selectedSportCenter?.name}</h4></React.Fragment>;
      case 2:
        return <React.Fragment><h4>{selectedCourt?.name}</h4></React.Fragment>;
      case 3:
        return <React.Fragment><h4>{formatDate(selectedDate)}</h4></React.Fragment>;
      case 4:
        return <React.Fragment><h4>{formatDate2(selectedDate)} {formatHour(selectedHour)}</h4></React.Fragment>;
      default:
        return null;
    }
  };

  return (
    <div>
      {stage > 0 && (
        <button className="back-button" onClick={OnhandleBack}>
	      <IoArrowBack size={40} />
        </button>
      )}
      {renderSelectedItem() && (
        <div className="selected-item">
          <div className="selected-item-box">
            {renderSelectedItem()}
          </div>
        </div>
      )}
	  { (true) && 
		<button className="update-button" onClick={OnhandleUpdate}>
			<IoSyncOutline size={40} />
		</button>
	  }
    </div>
  );
};

export default SelectedDetails;
